
/* Application entry-point */

import './css/ko-user-table-filter-field.css';
import './css/user-tables.css';
import './css/tabs.css';

import pages from './pages/index.js';
import components from './components/index.js';
import dialogs from './dialogs/index.js';

window.Grape.pages.registerPages(pages);
window.Grape.component.registerComponents(components);;
window.Grape.dialog.registerDialogs(dialogs);

/* "main" */
document.addEventListener('DOMContentLoaded', () => {
});
