
// COMPONENTS
import comp1 from './ko-user-table-data-edit/ko-user-table-data-edit.js';
import comp2 from './ko-user-table-data-view/ko-user-table-data-view.js';
import comp4 from './ko-user-table-filter-field/ko-user-table-filter-field.js';

import importer_upload from './dbschema-import-from-upload/component.js';
import datagrid_table from './canvas-datagrid-table/component.js';

export default [
	comp1,
	comp2,
	comp4,
	importer_upload,
	datagrid_table
];
