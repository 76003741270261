/**
 * Page with sidebar example
 */
import template from './setup.html';

class SetupPageVM
{
	constructor (page) 
	{
		this.page = page;

		this.tabs = ko.observableArray([
		{
			route: '[/ui/dbschema/setup/]schemas',
			title: 'Schemas',
			icon: 'fad fa-info-circle'
		},
		{
			route: '[/ui/dbschema/setup/]tables',
			title: 'Tables',
			icon: 'fad fa-info-circle'
		},
		{
			route: '[/ui/dbschema/setup/]users',
			title: 'Users & Permissions',
			icon: 'fad fa-info-circle'
		}

		]); 

		this.current_page_route = ko.observable();
	}
	
	load_tab (page_route)
	{
		window.localStorage.setItem('dbschema.setup.last_sidebar_page', page_route);
		const element = this.page.element.querySelector('#page_content');
		this.current_page_route(page_route);
		const page = Grape.pages.find_page_by_id(page_route);
		if (!page)
			throw new Error(`Page with id "${page_route}" not found`);
		Grape.pages.load_page(page, element, {}, {}, () => {});
	}
}

class SetupPage
{
	constructor (bindings, element, page) 
	{
		this.viewModel = new SetupPageVM(this);
		this.bindings = bindings;
		this.element = element;
	}
	
	async init ()
	{
		document.title = 'Setup';
		if (window.localStorage.getItem('dbschema.setup.last_sidebar_page'))
			this.viewModel.load_tab(window.localStorage.getItem('dbschema.setup.last_sidebar_page'));
		else
			this.viewModel.load_tab('[/ui/dbschema/setup/]schemas');
	}
}

export default {
	route: '[/]ui/dbschema/setup',
	page_class: SetupPage,
	template: template
}
