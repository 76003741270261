
import template from './TableDataImport.html';

class TableDataImportVM
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.currentTab = ko.observable('default');
		this.importMethods = ko.observableArray([
			{
				description: 'Upload from file on disk',
				name: 'Upload',
				component: 'dbschema-import-from-upload'
			},
			{
				description: 'Fetch from HTTP call',
				name: 'HTTPCall',
				component: ''
			},
			{
				description: 'Select from FileStore',
				name: 'FileStore',
				component: ''
			},
			{
				description: 'Copy from existing table or report',
				name: 'Table',
				component: ''
			}
		]);
		this.importMethod = ko.observable();
	}

	btnSave()
	{
		let data = {};
		this.dialog.close(data);
	}

	btnClose()
	{
		this.dialog.close();
	}
}

class TableDataImportDialog
{
	constructor(bindings, element)
	{
		this.bindings = bindings || {};
		this.viewModel = new TableDataImportVM(this); // Name of the ViewModel defined above
		this.name = 'TableDataImportDialog'; // Unique dialog name
		
		if (this.bindings.fields)
		{
			this.viewModel.fields(fields);
		}
	}
}

export default {
	name: 'TableDataImport',
	dialog_class: TableDataImportDialog,
	provider: 'ps',
	template: template
}
