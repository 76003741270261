

/**
 * Page with sidebar example
 */
import template from './schemas.html';

class SchemasPageVM
{
	constructor (page) 
	{
		this.page = page;
		this.loading = ko.observable(false);

		this.schemas = ko.observableArray();
		this.current_schema_name = ko.observable();
		this.tables = ko.observableArray();

	}
	
	async load()
	{
		const result = await Grape.fetches.getJSON('/api/record', {schema: 'dbschema', table: 'v_schemas'});
		this.schemas(result.records);
	}

	async loadSchema(schema_name)
	{
		this.current_schema_name(null);
		this.tables([]);
		this.loading(true);
		const result = await Grape.fetches.getJSON('/api/record', {schema: 'dbschema', table: 'v_tables', filter: [{f: 'schema_name', o: '=', v: schema_name}]});
		this.tables(result.records);
		this.current_schema_name(schema_name);
		window.localStorage.setItem('dbschema.schemas.last_schema', schema_name);
		this.loading(false);
	}

	async createSchema_click()
	{
		let schema_name = await window.Grape.alerts.prompt({message: 'Enter new schema name'});
		if (!schema_name || !schema_name.prompt)
			return;

		schema_name = schema_name.prompt;

		let result = await Grape.fetches.postJSON('/api/dbschema/schema', {schema_name});
		if (result.status != 'OK')
		{
			await window.Grape.alerts.alert({
				type: 'error',
				title: 'Something went wrong',
				message: result.message || 'Unable to save schema'
			})
			return;
		}
		await this.load();
	}

	async btnCreateTable_click()
	{
		window.Grape.navigate('/ui/dbschema/tables/edit', {schema_name: this.current_schema_name()});
	}
}

class SchemasPage
{
	constructor (bindings, element, page) 
	{
		this.viewModel = new SchemasPageVM(this);
		this.bindings = bindings;
		this.element = element;
	}
	
	async init ()
	{
		document.title = 'Schemas';
		await this.viewModel.load();
		if (window.localStorage.getItem('dbschema.schemas.last_schema'))
			this.viewModel.loadSchema(window.localStorage.getItem('dbschema.schemas.last_schema'));
		else
			if (this.viewModel.schemas()?.length)
				this.viewModel.loadSchema(this.viewModel.schemas()[0].schema_name);

	}
}

export default {
	route: '[/]ui/dbschema/schemas',
	page_class: SchemasPage,
	template: template
}

