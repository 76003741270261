import template from './schemas.html';

class SetupSchemasVM
{
	constructor (page)
	{
		this.page = page;
		this.schemas = ko.observableArray([]);

		// pagination 
		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(25);
		this.page_count = ko.observable(1);

		this.current_page_size.subscribe((value) => {
			this.page.onload = true;
		});
	}

	page_click (page_number)
	{
		this.current_page_number(page_number);
		this.load();
	}

	async load()
	{
		let options = {
			schema: 'dbschema',
			table: 'v_pg_schemas'
		};

		// LOGIC: Pagination
		if (this.current_page_number() && this.current_page_size())
		{
			options.limit = this.current_page_size();
			options.offset = (this.current_page_number()-1) * this.current_page_size();
		}

		let result = await Grape.fetches.getJSON('/api/record', options);

		if (result.status != 'ERROR')
		{
			this.schemas(result.records || []);
			this.page_count(Math.floor(result.total/result.limit)+1);
		}
		else
			Grape.alerts.alert({type: 'error', message: result.message || 'Fail' });
	}

	async btnImport(schema)
	{
		if (!(await Grape.alerts.confirm({type: 'warn', message: 'Are you sure?'})))
			return;
		const response = await Grape.fetches.postJSON(`/api/dbschema/import-object`, {schema_name: schema.schema_name});
		await Grape.alerts.alert({message: `${response.table_count} tables imported`});
		await this.load();
	}

	async btnCreateSchemas()
	{
		let schema_name = await window.Grape.alerts.prompt({message: 'Enter new schema name'});
		if (!schema_name || !schema_name.prompt)
			return;

		schema_name = schema_name.prompt;

		let result = await Grape.fetches.postJSON('/api/dbschema/schema', {schema_name});
		if (result.status != 'OK')
		{
			await window.Grape.alerts.alert({
				type: 'error',
				title: 'Something went wrong',
				message: result.message || 'Unable to save schema'
			})
			return;
		}
		await this.load();
	}
}

class SetupSchemasPage
{
	constructor (bindings, element, page)
	{
		this.viewModel = new SetupSchemasVM(this);
		this.bindings = bindings;
		document.title = 'DB setup: Schemas';
	}

	async init ()
	{
		this.viewModel.load();
	}

	teardown ()
	{
	}
}

export default {
	route: '[/ui/dbschema/setup/]schemas',
	page_class: SetupSchemasPage,
	template: template
}
