
import template from './template.html';

/**
 * This is an example knockout component class. Note the export section at the end of the file.
 * @kind component
 * @class SampleComponent
 * @description Sample Component that shows the name value
 */
class ComponentViewModel {
	constructor(params) {
		this.params = params;

		this.myname = ko_helper.safe_observable(params.myname);
		this.fields = ko_helper.safe_observableArray(params.fields);
		this.records = ko_helper.safe_observableArray(params.records);

	}
	async btnCreateNew_click()
	{
		let obj = {};
		for (let field of this.fields())
		{
			let element = document.querySelector(`input[class="utd-input-field"][data-field="${field.name}"][data-record-id="-1"]`);
			if (!element)
				throw new Error('Element not found');
			obj[field.name] = element.value;
		}

		let response = await fetch('/api/record', {
			method: 'POST',
			headers: {'Content-type': 'application/json'},
			body: JSON.stringify({
				schema: this.schema_name(),
				table: this.table_name(),
				values: obj
			})
		});
		let data = await response.json();
		if (response.ok)
		{
			console.log('create record data=',data);
			this.btnClearNew_click();
		}
		else
		{
			// TODO show error
		}
	}

	async btnDelete_click(record)
	{
	}

	async btnUpdate_click(record)
	{
	}

	async btnView_click(record)
	{
	}

	async btnClearNew_click()
	{
		for (let field of this.fields())
		{
			let element = document.querySelector(`input[class="utd-input-field"][data-field="${field.name}"][data-record-id="-1"]`);
			if (!element)
				throw new Error('Element not found');
			element.value = '';
		}
	}

};

export default {
	name: 'ko-user-table-data-edit',
	viewModel: ComponentViewModel,
	module_type: 'ko',
	template: template
};
