import template from './table-data.html'

class UserTableDataVM
{
	constructor(page)
	{
		this.page = page;
		this.schema_name = ko.observable('ut');
		this.table_name = ko.observable('');
	}

	async btn_export_click()
	{
		let result = await Grape.dialog.open('TableDataExport', {});
	}

	async btn_import_click()
	{
		let result = await Grape.dialog.open('TableDataImport', {schema_name: this.schema_name(), table_name: this.table_name()});
	}

	btn_permissions_click()
	{
		console.log('TODO btn_permissions_click');
	}

	btn_triggers_click()
	{
		console.log('TODO btn_triggers_click');
	}

	btn_maintenance_click()
	{
		console.log('TODO btn_maintenance_click');
	}
}

class UserTableDataPage
{
	constructor(bindings)
	{
		this.viewModel = new UserTableDataVM(this);

		if (bindings.schema_name && bindings.table_name)
		{
			this.viewModel.schema_name(bindings.schema_name);
			this.viewModel.table_name(bindings.table_name);

			localStorage.setItem('ut.tableschema', bindings.schema_name);
			localStorage.setItem('ut.tablename', bindings.table_name);
		}
		else if (localStorage.getItem('ut.tableschema') && localStorage.getItem('ut.tablename'))
		{
			this.viewModel.schema_name(localStorage.getItem('ut.tableschema'));
			this.viewModel.table_name(localStorage.getItem('ut.tablename'));
		}
	}

	async init()
	{
		document.title = 'View Table';
	}
}

export default {
	route: '[/]ui/dbschema/data/:schema_name/:table_name',
	page_class: UserTableDataPage,
	template: template

};
