import template from './table-edit.html'

class TableEditVM
{
	constructor(page)
	{
		this.page = page;
		this.is_new = ko.observable(true);

		this.schema_name = ko.observable('ut');
		this.table_name = ko.observable('');

		this.available_types = ko.observableArray([
			{name: 'TEXT'},
			{name: 'INTEGER'},
			{name: 'DATE'},
			{name: 'TIMESTAMPTZ'},
			{name: 'INTERVAL'}
		]); // TODO fetch from API

		this.new_column_name = ko.observable('');
		this.new_column_type = ko.observable('');

		this.columns = ko.observableArray([]);

		this.available_default_methods = ko.observableArray([
			{title: 'None', value: 'none'},
			{title: 'Copy from existing column', value: 'column'},
			{title: 'Copy from another table', value: 'other table'}
		]);
	}

	async loadExisting()
	{
		let response = await fetch(`/api/user-table/${this.schema_name()}/${this.table_name()}`);
		let data = await response.json();
		console.log('table info data=',data);

		let columns = [];
		for (let col of data.columns)
		{
			col.col_indicator = ko.observable('');
			col.default_value = '';
			columns.push(col);
		}
		console.log('columns:',columns);

		this.columns(columns);
	}

	btnAddColumn_click()
	{
		if (this.new_column_name() == '')
			return;
		if (this.new_column_type() == '')
			return;

		let new_column = {
			name: this.new_column_name(),
			type: this.new_column_type(),
			col_indicator: ko.observable('NEW'),
			default_value: ''
		};
		this.columns.push(new_column);
		this.new_column_name('');
		this.new_column_type('');
	}

	btnDeleteColumn_click(col)
	{
		if (col.col_indicator() === 'NEW')
		{
			let columns = [];
			for (let f of this.columns())
				if (col.name != f.name)
					columns.push(f);
			this.columns(columns);
		}
		else if (col.col_indicator() === 'REMOVE')
		{
			col.col_indicator('');
		}
		else
		{
			col.col_indicator('REMOVE');
		}
	}

	async btnCancel_click()
	{
		let response = await Grape.alerts.confirm({type: 'warning', title: 'Warning', message: 'Are you sure you want to cancel the creation of this table?'});

		if (response)
			window.Grape.navigate('/ui/dbschema/schemas');
	}

	async btnSave_click()
	{
		if (this.is_new() === true)
		{
			let body = {
				schema_name: this.schema_name(),
				table_name: this.table_name(),
				columns: this.columns()
			}

			let data = await Grape.fetches.postJSON('/api/dbschema/tables/create', body); 
			console.log(data);

			if (data.status == 'OK')
			{
				await Grape.alerts.alert({
					type: 'success',
					title: 'Success',
					message: 'Table created'});
				Grape.navigate(`/ui/dbschema/data/${body.schema_name}/${body.table_name}`);
			}
			else
			{
				alert(':(');
			}
		}
		else
		{
			console.log('not new');
			let changes = [];

			let idx = 0;
			for (let col of this.columns())
			{
				if (col.col_indicator() === '')
					continue;
				idx++;
				let change = {idx: idx, col: {name: col.name, type: col.type}};
				if (col.col_indicator() === 'NEW')
					change.action = 'add';
				else if (col.col_indicator() === 'REMOVE')
					change.action = 'remove';
				changes.push(change);
			}
			
			let body = {
				schema_name: this.schema_name(),
				table_name: this.table_name(),
				changes: changes
			}

			let response = await fetch('/api/user-table/alter', {method: 'POST', body: JSON.stringify(body), headers: {'Content-type': 'application/json'}});
			let data = await response.json();
			console.log(data);

			Grape.alert({
				type: 'success',
				title: 'Success',
				message: 'Table altered'});
		}
	}
}

class TableEditPage
{
	constructor(bindings)
	{
		this.viewModel = new TableEditVM(this);
		if (bindings.schema_name)
			this.viewModel.schema_name(bindings.schema_name);
		if (bindings.table_name)
		{
			this.viewModel.is_new(false);
			this.viewModel.table_name(bindings.table_name);
			this.viewModel.loadExisting();
		}
		else
		{
			this.viewModel.is_new(true);
		}
	}

	async init () {
		document.title = 'Edit';
	}
}

export default {
	route: '[/]ui/dbschema/tables/edit',
	page_class: TableEditPage,
	template: template

};

