
import template from './template.html';

/**
 * This is an example knockout component class. Note the export section at the end of the file.
 * @kind component
 * @class SampleComponent
 * @description Sample Component that shows the name value
 */
class ComponentViewModel {
	constructor(params) {
		this.params = params;

		this.schema_name = ko_helper.safe_observable(params.schema_name);
		this.table_name = ko_helper.safe_observable(params.table_name);

		this.fields = ko_helper.safe_observableArray(params.fields);
		this.records = ko_helper.safe_observableArray(params.records);

		this.default_filter = ko_helper.safe_observableArray(params.filter, []);
		this.filter = ko.observableArray();
		this.page_number = ko_helper.safe_observable(params.page_number || 1);
		this.max_pages = ko_helper.safe_observable(params.max_pages || 5);
		this.page_count = ko_helper.safe_observable(params.page_count || 1);
		this.limit = ko_helper.safe_observable(params.limit || 10);
		this.total = ko_helper.safe_observable(params.total || 0);
		this.offset = ko_helper.safe_observable(params.offset || 0);
		this.result_count = ko_helper.safe_observable(params.total || 0);
		this.sortfield = ko_helper.safe_observable(params.sortfield || null);
		this.sortorder = ko_helper.safe_observable(params.sortorder || 'ASC');

		// New filter field
		this.operators = ko.observableArray([
			'=', '>', '<', '>=', '<=', '!=', '@@', 'LIKE', 'ILIKE'
		]);
		this.show_new_filter = ko.observable(false);
		this.new_filter_field = ko.observable();
		this.new_filter_operator = ko.observable();
		this.new_filter_value = ko.observable();

		// Display options
		this.hide_result_count_view = ko_helper.safe_observable(params.hide_result_count_view || false);
		this.hide_utilities = ko_helper.safe_observable(params.hide_utilities || false);

		// Subscribe to observables
		// this.fields_render = ko.observableArray([]);
		this.fields.subscribe((nv) => {
			if (!Array.isArray(nv))
				return;
			// let fr = [];
			for (let n of nv)
			{
				if (!n.title)
				{
					let s1 = n.name.split('_');
					let newname = '';
					for (let s2 of s1)
					{
						if (newname != '')
							newname = newname + ' ';
						let s3 = s2.substring(0, 1).toUpperCase() + s2.substring(1);
						newname = newname + s3;
					}
					n.title = newname;
				}
				// fr.push(n);

				if (!n.span_template)
				{
					if ([
						'TIMESTAMPTZ', 'DATE', 'TIMESTAMP'
					].indexOf(n.type.toUpperCase()) >= 0)
					{
						n.span_template = 'template-datetime';
					}
					else
					{
						n.span_template = 'template-text';
					}
				}
			}
			// this.fields_render(fr);
		});

		this.init();
		this.filter.subscribe(() => {
			this.fetchData();
		});
	}

	async init() {
		await this.fetchTableInfo();
		await this.fetchData();
	}

	async fetchTableInfo() {
		let response = await fetch(`/api/user-table/${this.schema_name()}/${this.table_name()}`);
		let data = await response.json();
		console.log('table info data=', data);

		let fields = [];
		for (let field of data.fields)
		{
			fields.push(field);
		}

		this.fields(fields);
	}

	async fetchData() {
		let filter = this.default_filter() || [];
		filter = filter.concat(this.filter() || []);

		let params = {
			schema: this.schema_name(),
			table: this.table_name(),
			limit: this.limit(),
			page_number: this.page_number(),
			filter: filter
		};

		if (this.sortfield())
		{
			params.sortfield = this.sortfield();
			params.sortorder = this.sortorder();
		}

		let url = Grape.fetches.buildURL(`/api/record`, params, window.location);
		let response = await fetch(url);
		let data = await response.json();
		console.log('data=', data);
		this.records(data.records);
		this.page_count(data.total_pages);
		this.result_count(data.result_count);
		this.total(data.total);
		this.offset(data.offset);
	}

	async tblDataViewHeader_click(field) {
		if (this.sortfield() == field.name)
		{
			if (this.sortorder() == 'ASC')
				this.sortorder('DESC');
			else
				this.sortorder('ASC');
		}
		else
		{
			this.sortfield(field.name);
		}
		await this.fetchData();
	}

	async btnView_click(record) {
	}

	async page_click(page_number) {
		this.page_number(page_number);
		this.fetchData();
	}

	async row_click(row) {
		if (this.params.on_row_click instanceof Function)
		{
			this.params.on_row_click(row);
		}
	}

	btnShowAddFilter_click() {
		this.show_new_filter(true);
	}

	async btnFilterAdd_click() {
		if (!this.new_filter_field())
			return;
		if (!this.new_filter_value())
			return;
		let filters = this.filter();
		filters.push({
			field: this.new_filter_field().name,
			oper: this.new_filter_operator(),
			value: this.new_filter_value()
		});
		this.filter(filters);
		this.show_new_filter(false);
	}

	async btnFilterRemove_click(itm) {
		this.filter.remove(itm);
	}
}

export default {
	name: 'ko-user-table-data-view',
	viewModel: ComponentViewModel,
	module_type: 'ko',
	template: template
};
