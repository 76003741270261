
import template from './TableDataExport.html';

//const template = '';

class TableDataExportVM
{
	constructor(dialog)
	{
		this.dialog = dialog;

		this.currentTab = ko.observable('default');
		this.exportFormats = ko.observableArray([
			{
				description: 'Comma-separated (CSV)',
				name: 'CSV',
				component: 'dbschema-export-csv'
			},
			{
				description: 'Excel Template',
				name: 'XLSX',
				component: ''
			},
			{
				description: 'PDF',
				name: 'PDF',
				component: 'dbschema-export-pdf'
			},
			{
				description: 'XML',
				name: 'XML',
				component: 'dbschema-export-xml'
			},
			{
				description: 'JSON',
				name: 'JSON',
				component: 'dbschema-export-json'
			},


		]);
		this.exportFormat = ko.observable();

		this.deliveryMethods = ko.observableArray([
			{
				description: 'Download',
				name: 'Download',
				component: ''
			},
			{
				description: 'Email',
				name: 'Email',
				component: ''
			},
			{
				description: 'Upload to server',
				name: 'Upload',
				component: 'dbschema-export-upload'
			},

		]);
		this.deliveryMethod = ko.observable();

		this.checkEncryption = ko.observable(false);

	}

	btnSave()
	{
		let data = {};
		this.dialog.close(data);
	}

	btnClose()
	{
		this.dialog.close();
	}
}

class TableDataExportDialog
{
	constructor(bindings, element)
	{
		this.bindings = bindings || {};
		this.viewModel = new TableDataExportVM(this); // Name of the ViewModel defined above
		this.name = 'TableDataExportDialog'; // Unique dialog name
		
		if (this.bindings.fields)
		{
			this.viewModel.fields(fields);
		}
	}
}

export default {
	name: 'TableDataExport',
	dialog_class: TableDataExportDialog,
	provider: 'ps',
	template: template
}
