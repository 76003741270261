import template from './users.html';

class SetupUsersVM
{
	constructor (page)
	{
		this.page = page;
		this.users = ko.observableArray([]);

		// pagination 
		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(25);
		this.page_count = ko.observable(1);

		this.current_page_size.subscribe((value) => {
			this.page.onload = true;
		});
	}

	page_click (page_number)
	{
		this.current_page_number(page_number);
		this.load();
	}

	async load()
	{
		console.log('TODO: load users');

		/*
		let options = {
			schema: 'dbschema',
			table: 'v_pg_users'
		};

		// LOGIC: Pagination
		if (this.current_page_number() && this.current_page_size())
		{
			options.limit = this.current_page_size();
			options.offset = (this.current_page_number()-1) * this.current_page_size();
		}

		let result = await Grape.fetches.getJSON('/api/record', options);

		if (result.status != 'ERROR')
		{
			this.users(result.records || []);
			this.page_count(Math.floor(result.total/result.limit)+1);
		}
		else
			Grape.alerts.alert({type: 'error', message: result.message || 'Fail' });
		*/
	}

	async btnImport(user)
	{
		console.log('TODO: import users: ', user);
	}

	btnCreateUsers()
	{
		console.log('TODO: create users');
	}
}

class SetupUsersPage
{
	constructor (bindings, element, page)
	{
		this.viewModel = new SetupUsersVM(this);
		this.bindings = bindings;
		document.title = 'DB setup: Users';
	}

	async init ()
	{
		this.viewModel.load();
	}

	teardown ()
	{
	}
}

export default {
	route: '[/ui/dbschema/setup/]users',
	page_class: SetupUsersPage,
	template: template
}
