import template from './template.html';


/**
 * Component VM for dbschema-import-from-upload
 */
class ComponentVM {
	/**
	 * Default class constructor
	 *
	 */
	constructor(params) {
	}

}

export default {
	name: 'dbschema-import-from-upload',
	viewModel: ComponentVM,
	module_type: 'ko',
	template: template
};
