
// PAGES 
import table_list from './tables/table-list.js';
import table_edit from './tables/table-edit.js';
import table_data from './tables/table-data.js';
import schemas from './schemas/schemas.js';
import setup from './setup/setup.js';
import setup_schemas from './setup/schemas/schemas.js';
import setup_tables from './setup/tables/tables.js';
import setup_users from './setup/users/users.js';

export default [
	table_list,
	table_edit,
	table_data,
	schemas,
	setup,
	setup_schemas,
	setup_tables,
    setup_users
];

