import template from './template.html';

let grid;

/**
 * Component VM for canvas-datagrid table
 */
class ComponentVM
{
	constructor (params)
	{
		this.params = params;

		this.display_save = ko.observable(false);
		this.schema_name = ko_helper.safe_observable(params.schema_name || '');
		this.table_name = ko_helper.safe_observable(params.table_name || '');

		this.records = ko.observableArray([]);
		this.column_names = ko.observableArray([]);
		this.filters = ko.observableArray([]);

		// pagination 
		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(25);
		this.page_count = ko.observable(1);
		this.record_total = ko.observable();
		this.total_pages = ko.observable();
		this.pagination_summary = ko.observable('');
		this.offset = ko.observable();

		// track table changes
		this.edited_cells = ko.observableArray([]);
		this.deleted_rows = ko.observableArray([]);
		this.added_rows = ko.observableArray([]);

		this.current_page_size.subscribe((value) => {
			this.onload = true;
		});

		this.filters.subscribe(() => this.updateData());
		this.schema_name.subscribe(() => this.updateData());
		this.table_name.subscribe(() => this.updateData());
		
		this.updateData();
	}

	async page_click (page_number)
	{
		if (this.display_save())
		{
			let response = await Grape.alerts.confirm({message: 'This will cancel any unsaved changes. Are you sure?', type: 'warning', title: 'Confirm'});
			if (response)
			{
				this.edited_cells([]);
				this.deleted_rows([]);
				this.added_rows([]);
				this.display_save(false);

				this.current_page_number(page_number);
				this.updateData();
			}
			else
				return;
		}
		else
		{
			this.current_page_number(page_number);
			this.updateData();
		}
	}

	async updateData ()
	{
		if (this.schema_name() && this.table_name())
		{
			// Get records
			let options = {
				schema: this.schema_name(),
				table: this.table_name()
			};

			// LOGIC: Pagination
			if (this.current_page_number() && this.current_page_size())
			{
				options.limit = this.current_page_size();
				options.offset = (this.current_page_number()-1) * this.current_page_size();
			}

			// LOGIC: Filters
			let filters = [];
			for (let f of this.filters())
				filters.push({field: f.field, operator: f.operator, value: f.value});

			if (filters.length)
				options.filter = filters;

			let response = await Grape.fetches.getJSON('/api/record', options);

			if (response.status != 'ERROR')
			{
				this.records(response.records || []);

				// Pagination
				this.page_count(Math.ceil(response.total/response.limit));
				this.record_total(response.total);
				this.total_pages(response.total_pages);
				this.offset(options.offset);
				
				// Summary
				this.pagination_summary(`Showing ${this.offset()} - ${this.current_page_number() * this.current_page_size()} of ${this.record_total()}`);
			}
			else
				Grape.alerts.alert({type: 'error', message: response.message || 'Fail' });

			// Get column_names for filter
			let data = await Grape.fetches.getJSON(`/api/dbschema/tables/${this.schema_name()}/${this.table_name()}`);

			if (data.columns)
			{
				let column_names = [];

				for (let field of data.columns)
				{
					field.input = ko.observable('');
					column_names.push({name: field.column_name});
				}

				this.column_names(column_names);
			}

			this.grid_handler();
		}
	}

	grid_handler()
	{
		grid = window.canvasDatagrid({
			allowColumnReordering: true,
			editable: true,
			hoverMode: 'row'
		});

		// Customize context menu
		grid.addEventListener('contextmenu', (e) => {
			if (e.cell)
			{
				e.items.push({
					title: 'Delete Row',
					click: (ev) => {
						if (!this.deleted_rows()[e.cell.rowIndex])
						{
							let row_data = {
								rowIndex: e.cell.rowIndex,
								data: this.records()[e.cell.rowIndex]
							};

							this.deleted_rows.push(row_data);
							this.display_save(true);
						}
						else
							Grape.alerts.alert({type: 'warning', title: 'Delete Column', message: 'Cannot delete row containing headers!'});
					}
				});
			}
		});

		grid.addEventListener('rendercell', (e) => {
			let rowIndex = e.cell.rowIndex;
			let columnIndex = e.cell.columnIndex;
		
			if (this.deleted_rows().some(row => row.rowIndex === rowIndex))
			{
				// Check if row is deleted - light red
				e.ctx.fillStyle = '#ffcccc';
			}
			else if (this.added_rows().some(row => row.rowIndex === rowIndex))
			{
				// Check if row is added - light green
				e.ctx.fillStyle = '#ccffcc';
			}
			else if (this.edited_cells().some(edit => edit.row === rowIndex && edit.column === columnIndex && !this.added_rows().some(row => row.rowIndex === rowIndex)))
			{
				// Check if cell is edited, excluding new rows - light orange
				e.ctx.fillStyle = '#ffeca8';
			}
		});		
	
		grid.addEventListener('endedit', (e) => {
			if (this.added_rows().some(row => row.rowIndex === e.cell.rowIndex))
			{
				// This row is new, dont track as edit
				this.added_rows().find(row => row.rowIndex === e.cell.rowIndex).data[e.cell.columnIndex] = e.value;
			}
			else
			{
				let edit_info = {
					oldValue: e.cell.value,
					newValue: e.value,
					row: e.cell.rowIndex,
					column: e.cell.columnIndex
				};
				this.edited_cells.push(edit_info);
			}
		
			this.display_save(true);
		});

		// remove grid when data/pagination/filters are updated
		grid.data = [];
		let datagrid = document.querySelector('.datagrid');
		if (datagrid && datagrid.firstChild)
			datagrid.removeChild(datagrid.firstChild);

		grid.data = this.records();
		document.querySelector('.datagrid').appendChild(grid);
	}

	btn_add_row_click()
	{
		let rowIndex = grid.data.length;
		grid.addRow({});

		let row_data = { rowIndex: rowIndex, data: grid.data[rowIndex] || {} };
	
		this.added_rows.push(row_data);
		this.display_save(true);
	
		grid.draw();
	}
	
	async btn_save_records_click()
	{
		let response = await Grape.alerts.confirm({type: 'info', title: 'Save Changes', message: 'Are you sure you want to save changes?'});

		if (!response)
			return;

		if (this.deleted_rows().length > 0)
		{
			for (let row of this.deleted_rows())
			{
				let filter = Object.keys(row.data).map(key => ({
					field: key,
					operand: '=',
					value: row.data[key]
				}));
	
				let delete_obj = {
					schema: this.schema_name(),
					table: this.table_name(),
					filter: filter
				};
	
				let response = await fetch('/api/record/', {
					method: 'DELETE',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(delete_obj)
				});
	
				if (!response.ok)
					Grape.alerts.alert({type: 'error', title: 'Delete Row', message: 'Error deleting row!'});
			}
		}

		if (this.added_rows().length > 0)
		{
			for (let row of this.added_rows())
			{
				let insert_obj = {
					schema: this.schema_name(),
					table: this.table_name(),
					values: row.data
				}

				let response = await fetch('/api/record/', {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(insert_obj)
				});

				if (!response.ok)
					Grape.alerts.alert({type: 'error', title: 'Add Row', message: 'Error adding row!'});
			}
		}

		if (this.edited_cells().length > 0)
		{
			for (let edit of this.edited_cells())
			{
				let original_row_data = this.records()[edit.row];
				let field_to_update = Object.keys(original_row_data).find(key => original_row_data[key] == edit.newValue);
		
				if (!field_to_update) continue;
		
				let filter = Object.entries(original_row_data).map(([key, value]) => ({
					field: key,
					operand: '=',
					value: key === field_to_update ? edit.oldValue : value
				}));
		
				let values = { ...original_row_data, [field_to_update]: edit.newValue };
		
				let edit_obj = {
					schema: this.schema_name(),
					table: this.table_name(),
					filter,
					values,
					returning: '*'
				};
		
				let response = await fetch('/api/record/', {
					method: 'PATCH',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(edit_obj)
				});
		
				if (!response.ok) Grape.alerts.alert({type: 'error', title: 'Edit Cell', message: 'Error editing cell!'});
			}
		}

		this.display_save(false);

		this.edited_cells([]);
		this.deleted_rows([]);
		this.added_rows([]);
		
		this.updateData();
		Grape.alerts.alert({type:'success', title: 'Save Complete', message: 'Changes saved!'});
	}
}

export default {
	name: 'ko-datagrid-table',
	viewModel: ComponentVM,
	module_type: 'ko',
	template: template
};
